<template>
  <div>
    <div
      v-for="(element, index) in fieldsValues"
      :key="index"
    >
      <div
        v-show="!element._destroy"
        class="mb-3"
      >
        <div class="mb-2 flex items-center">
          <!-- label with audio number -->
          <form-label
            :label="audioLabel(index)"
            class="text-sm"
          />

          <app-card-item
            v-if="nestedResource(element)?.attributes?.source === 'textToSpeech'"
            class="text-xs w-fit px-3 py-1 font-bold ml-2"
            background-classes="bg-gray-300"
          >
            {{ t('ttmt.media.audio.generated_audio') }}
          </app-card-item>
        </div>

        <!-- nested form fields -->
        <audios-form-nested-fields
          :ref="(refItem) => setFormFieldsRef(index, refItem)"
          :resource="nestedResource(element)"
          :name-prefix="`${name}.${index}`"
          @deleted="handleAudioDeleted(element, index)"
          @file-uploaded="handleFileUploaded"
        />
      </div>
    </div>

    <!-- add  & records buttons -->
    <div
      ref="audiosAddButtons"
      class="flex items-center justify-center"
    >
      <app-button
        feature="record"
        class="mr-4 flex-1"
        font-color-classes="text-theme-500 hover:text-theme-600"
        background-classes="bg-white"
        :hide-icon="false"
        padding="p-0"
        height="10"
        @click="displayRecorder"
      />

      <app-audio-recorder
        v-if="recorderDisplayed"
        class="shadow-lg shadow-theme-500/10 bottom-8"
        @closed="handleRecorderClosed"
        @saved="handleRecordSaved"
      />

      <app-button
        feature="add"
        :hide-icon="false"
        :hide-text="true"
        :icon-props="{ size: 'text-4xl' }"
        padding="p-0"
        height="10"
        font-color-classes="text-theme-500 hover:text-theme-600"
        background-classes="bg-white"
        class="flex-1"
        @click="handlePlusClick"
      />
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AudiosFormNestedFields from '@extranet/components/resources/point_of_interest/audios/AudiosFormNestedFields.vue'
import useFormNested from '@shared/hooks/form/formNested'
import AppAudioRecorder from '@shared/components/ui/AppAudioRecorder.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'

const props = defineProps({
  // json api resource used to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Attributes prefix name in form
  name: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const {
  nestedResource,
  handleFormAdd,
  handleFormRemove,
  markForDestruction,
  updateNestedFieldsValue,
  fieldsValues,
} = useFormNested(props)

const form = inject('form', ref({}))

const nestedFormsFieldsEls = ref([]) // form fields template refs
function setFormFieldsRef(index, element) {
  nestedFormsFieldsEls.value[index] = element
}

function handleAudioDeleted(element, index) {
  if (nestedResource(element)?.attributes?.source === 'textToSpeech') {
    // Disable audio refreshing
    form.value.setFieldValue('audio_refreshing', false)
  }

  // Use base remove handler
  handleFormRemove(index)
}

// ---------- AUDIO UPLOAD ----------

function handlePlusClick() {
  // Recorder not needed anymore when plus clicked, because file will be upload directly
  handleRecorderClosed()

  handleFormAdd(async (newAudioIndex) => {
    // Mark to destroy by default, it has to be unmark at file upload in the FormFields component
    markForDestruction(newAudioIndex)

    await nextTick()
    // Trigger file input upload
    nestedFormsFieldsEls.value[newAudioIndex].$refs.fileInput.click()

    // Set source
    updateNestedFieldsValue(newAudioIndex, { source: 'upload' })
  })
}

function audioLabel(index) {
  return `${t('validation.attributes.audio')} ${index + 1}`
}

function handleFileUploaded() {
  // Disable audio refreshing
  form.value.setFieldValue('audio_refreshing', false)
}

// ---------- AUDIO RECORDER ----------

const audiosAddButtons = ref() // template ref

const recorderDisplayed = ref(false)

function displayRecorder() {
  recorderDisplayed.value = true
}

function handleRecorderClosed() {
  recorderDisplayed.value = false
}

function handleRecordSaved(file) {
  handleFormAdd((newAudioIndex) => {
    // Save the recorded value and set source
    updateNestedFieldsValue(newAudioIndex, {
      file,
      source: 'record',
    })

    // Disable audio refreshing
    form.value.setFieldValue('audio_refreshing', false)

    handleRecorderClosed()
  })
}
</script>
