import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'

export default function breadcrumbPointOfInterest() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('ttmt.breadcrumbs.points_of_interest.points_of_interest'),
        link: false,
        to: '/points-of-interest',
      },
    ]
  }

  /**
   * Breadcrumb for show page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function show(pointOfInterest) {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: pointOfInterest.label ?? pointOfInterest.id,
        link: false,
        to: `/points-of-interest/${pointOfInterest.id}`,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for create page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function create() {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.create'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for edit page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function edit(pointOfInterest) {
    let breadcrumbs = show(pointOfInterest)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.edit'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for share page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function share(pointOfInterest) {
    let breadcrumbs = show(pointOfInterest)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.share'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for add to excursion page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function addExcursion(pointOfInterest) {
    let breadcrumbs = show(pointOfInterest)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.points_of_interest.add_excursion'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for report page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function report(pointOfInterest) {
    let breadcrumbs = show(pointOfInterest)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.report'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for claim page
   *
   * @param pointOfInterest
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function claim(pointOfInterest) {
    let breadcrumbs = show(pointOfInterest)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.claim'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  return {
    index,
    show,
    create,
    edit,
    share,
    report,
    addExcursion,
    claim,
  }
}
