<template>
  <app-button
    v-if="store.state.auth.user.authorizations.excursions.viewAny"
    font-size="sm"
    :label="t('ttmt.common.actions.add_excursion')"
    feature="add"
    :emphasis="emphasis"
    :hide-text="hideText"
    :hide-icon="hideIcon"
    @click="handleModalAction"
  />

  <!-- Modal -->
  <app-overlay
    :show="confirmModalDisplayed"
    @clicked="handleCancel"
  >
    <app-modal @closed="handleCancel">
      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ t('ttmt.points_of_interest.excursion_add.add_poi_to_excursion') }}
        </h4>
      </template>

      <div class="mb-7 text-left">
        <p class="mb-2">
          {{ t(
            'ttmt.points_of_interest.excursion_add.select_which',
            { point_of_interest_name: l10nTitle }
          ) }}
        </p>

        <p v-if="resource.relationships?.excursions?.length > 0">
          {{ t('ttmt.points_of_interest.excursion_add.already_in_excursions') }}
          :
          {{ l10nExcursionsTitles }}
        </p>
      </div>

      <point-of-interest-excursion-add-form
        :resource="resource"
        :additional-errors="formErrors"
        :submitting="formSubmitting"
        :cancel-modal="confirmModalDisplayed"
        @submitted="handleSubmit"
      />

      <app-separator
        :text="t('ttmt.auth.messages.or')"
        class="my-4"
      />

      <app-button
        :label="t('ttmt.points_of_interest.excursion_add.create_excursion')"
        display-classes="flex"
        class="mx-auto"
        @click="displayCreationModal"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import PointOfInterestExcursionAddForm from '@extranet/components/resources/point_of_interest/PointOfInterestExcursionAddForm.vue'
import {
  storeExcursionPointOfInterest,
} from '@shared/http/api'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
  // Hide icon or not
  hideIcon: {
    type: Boolean,
    default: true,
  },
})

const store = useStore()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()
const confirmModalDisplayed = ref(false)

// ---------- EXCURSION RELATION ----------

const formErrors = ref({})
const formSubmitting = ref(false)

function handleSubmit(attributes) {
  if (!formSubmitting.value) {
    formSubmitting.value = true
    const params = {
      data: {
        type: 'excursionpointofinterest',
        attributes: { ...attributes },
      },
    }

    storeExcursionPointOfInterest(params)
      .then(() => {
        store.commit('flashes/ADD_FLASH', { message: t('ttmt.points_of_interest.excursion_add.success'), type: 'success' })
        confirmModalDisplayed.value = false
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          formErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        formSubmitting.value = false
      })
  }
}

// ---------- POI ----------

const { l10nTitle } = usePointOfInterest(props)

const l10nExcursionsTitles = computed(() => (
  props.resource.relationships.excursions.map((exc) => (
    `"${getAttributeTranslation(exc.attributes.title)}"`
  )).join(', ')
))

// Display Modal
function handleModalAction() {
  confirmModalDisplayed.value = true
}

// Cancel modal
function handleCancel() {
  confirmModalDisplayed.value = false
}

// ---------- CREATION ----------

function displayCreationModal() {
  confirmModalDisplayed.value = false
  // Display the creation modal
  store.commit('modals/SET_PROPERTIES', {
    name: 'excursion_form',
    display: true,
    pointOfInterestId: props.resource.id,
    forceStep: 3,
  })
}
</script>
