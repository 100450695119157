<template>
  <div>
    <!-- Date fields -->
    <div
      class="flex flex-wrap gap-4"
    >
      <form-group
        type="date"
        :name="fromFieldName"
        :label="isRange ? t('ttmt.form.labels.point_of_interest.opening_date_from') : t('ttmt.form.labels.point_of_interest.opening_date')"
        error-label-i18n-key="date"
      />

      <form-group
        v-if="isRange"
        type="date"
        :name="toFieldName"
        :label="t('ttmt.form.labels.point_of_interest.opening_date_to')"
        error-label-i18n-key="date"
        :rules="`min.date:@${fromFieldName}`"
      />
    </div>

    <form-hint
      :hint="t('ttmt.form.hints.point_of_interest.opening_dates')"
      class="mb-3"
    />

    <!-- Range checkbox -->
    <form-group
      type="checkbox"
      :name="`${name}.is_range`"
      :label="t('ttmt.form.labels.point_of_interest.opening_date_range')"
      flex-direction="row"
      :submittable="false"
      :initial-value="!isNil(dates?.to)"
    />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { get, isNil } from 'lodash'

import FormGroup from '@shared/components/form/FormGroup.vue'
import FormHint from '@shared/components/form/FormHint.vue'

const { t } = useI18n()

const props = defineProps({
  // Prefix used for form group's names
  name: {
    type: String,
    required: true,
  },
})

const form = inject('form', ref({}))

const dates = computed(() => (
  get(form.value.values, `${props.name}`)
))

const isRange = computed(() => (
  get(form.value.values, `${props.name}.is_range`)
))

const fromFieldName = computed(() => (
  `${props.name}.from`
))

const toFieldName = computed(() => (
  `${props.name}.to`
))
</script>
