<template>
  <app-button
    v-if="resource.authorizations.update"
    font-size="sm"
    feature="edit"
    :emphasis="emphasis"
    :hide-text="hideText"
    :hide-icon="hideIcon"
    :icon-props="{ size: bigIcons ? 'text-3xl' : null }"
    @click="handleButtonBehavior"
  />

  <!-- Modal -->
  <app-overlay
    v-if="resource.authorizations.update && isSynchronized"
    :show="updateConfirmSyncModalDisplayed"
    @clicked="handleImportSyncDisableCancel"
  >
    <app-modal @closed="handleImportSyncDisableCancel">
      <app-confirm
        @cancelled="handleImportSyncDisableCancel"
        @confirmed="handleImportSyncDisableConfirm"
      >
        <p class="p-5">
          {{
            t('ttmt.points_of_interest.update.alert', { import_source: resource.attributes.import_source })
          }}
        </p>
        <p>
          {{
            t('ttmt.points_of_interest.update.confirm', {
              point_of_interest_name: getAttributeTranslation(resource.attributes.title)
            })
          }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on update handling
  updateCallback: {
    type: Function,
    default: () => ({}),
  },
  // Determine if button is text,
  // outline or contained
  emphasis: {
    type: String,
    default: 'high',
  },
  // Hide text or not
  hideText: {
    type: Boolean,
    default: false,
  },
  hideIcon: {
    type: Boolean,
    default: true,
  },
  // Display icons bigger
  bigIcons: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'importSyncDisabled',
])

const router = useRouter()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

const isSynchronized = computed(() => (
  props.resource?.attributes.held_by_auth_user
  && props.resource?.attributes?.source === 'import'
  && props.resource?.attributes?.import_source === 'DataTourisme'
  && props.resource?.attributes?.import_synchronizing
))

// ---------- UPDATE ----------

const updateConfirmSyncModalDisplayed = ref(false)

function handleButtonBehavior() {
  if (!isSynchronized.value) {
    router.push({
      name: 'edit-points-of-interest',
      params: { id: props.resource.id },
    })
  } else {
    // If Point of Interest import_synchronizing is true, show modal warning -->
    handleImportSyncDisabledModal()
  }
}

function handleImportSyncDisabledModal() {
  updateConfirmSyncModalDisplayed.value = true
}

function handleImportSyncDisableConfirm() {
  updateConfirmSyncModalDisplayed.value = false
  emits('importSyncDisabled')
}

function handleImportSyncDisableCancel() {
  updateConfirmSyncModalDisplayed.value = false
}
</script>
