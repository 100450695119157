<template>
  <div>
    <div v-if="isImport">
      <form-group
        type="switch"
        name="import_synchronizing"
      >
        <template #help-button>
          <p>
            {{
              t(
                'ttmt.form.hints.point_of_interest.sync_1',
                {
                  import_source: t(`ttmt.points_of_interest.import_source.${resource.attributes.import_source}`)
                }
              )
            }}
          </p>
          <p class="pb-4">
            {{ t('ttmt.form.hints.point_of_interest.sync_2') }}
          </p>
        </template>
      </form-group>
    </div>

    <div
      v-if="!importSynchronizing"
      class="grid grid-cols-1 lg:grid-cols-2 gap-4"
    >
      <!-- Details -->
      <app-card-item
        class="p-4"
      >
        <form-translated
          :form-group-props="{
            name: 'title',
            formLabelProps: {
              required: true,
            },
          }"
        />

        <form-translated
          :form-group-props="{
            name: 'description',
            type: 'textarea',
            formControlProps: {
              rows: 12,
              limitChars: 600,
            },
          }"
        />

        <form-group
          name="point_of_interest_category_id"
          rules="required"
          type="select"
          :form-control-props="{
            options: handleCategoriesSearch,
            filterable: false,
          }"
          @changed="handleSelectTagsRefresh"
        />

        <form-group
          v-if="isStarableCategory"
          name="stars"
          type="number"
          rules="min.numeric:0|max.numeric:5"
          :form-control-props="{
            min: 0,
            max: 5,
          }"
        />

        <form-group
          ref="selectTags"
          name="tags_ids"
          type="select"
          :form-control-props="{
            options: handleTagsSearch,
            filterable: false,
            mode: 'tags',
          }"
        />
      </app-card-item>

      <!-- Media -->
      <app-card-item
        v-if="!importSynchronizing"
        class="p-4 h-fit"
      >
        <form-label
          :label="t('validation.attributes.logo')"
          class="block mb-2"
        />

        <logo-form-fields
          name-prefix="logo"
          :resource="resource.relationships?.logo"
          class="mb-5"
        />

        <form-label
          :label="t('validation.attributes.gallery')"
          class="block mb-1"
        />

        <form-hint
          :hint="t('ttmt.form.hints.point_of_interest.images')"
          class="block mb-3"
        />

        <images-form-nested
          name="images"
          :resource="resource"
          class="mb-5"
        />

        <form-label
          :label="t('validation.attributes.video')"
          class="block mb-2"
        />

        <videos-form-nested
          name="videos"
          :resource="resource"
          class="mb-5"
        />

        <form-label
          :label="t('validation.attributes.audios')"
          class="block mb-3"
        />

        <form-group
          type="switch"
          name="audio_refreshing"
        >
          <template #help-button>
            <p>
              {{ t('ttmt.form.hints.point_of_interest.audio_refreshing_1', { language: t(`ttmt.locales.${form.values.locale}`) }) }}
            </p>
            <p>
              {{ t('ttmt.form.hints.point_of_interest.audio_refreshing_2') }}
            </p>
            <p class="mb-2">
              {{ t('ttmt.form.hints.point_of_interest.audio_refreshing_3') }}
            </p>
            <p>
              {{ t('ttmt.form.hints.point_of_interest.audio_refreshing_4') }}
            </p>
          </template>
        </form-group>

        <audios-form-nested
          name="audios"
          :resource="resource"
        />

        <!-- Specify source at creation only -->
        <form-group
          v-if="!resource.id"
          name="source"
          initial-value="extranet"
          type="hidden"
        />
      </app-card-item>

      <!-- Coordinates -->
      <app-card-item
        v-if="!importSynchronizing"
        class="p-4"
      >
        <address-form-fields-translated
          :resource="resource.relationships?.address"
        />

        <form-group
          name="locale"
          rules="required"
          type="select"
          :form-control-props="{
            options: localeOptions,
          }"
          :hint="t('ttmt.form.hints.point_of_interest.locale')"
        />

        <form-group
          rules="phone"
          name="contact.phone"
          type="tel"
        />

        <form-group
          rules="email"
          name="contact.email"
          type="email"
        />

        <form-group
          name="contact.website"
          type="url"
        />
      </app-card-item>

      <app-card-item class="p-4">
        <!-- Expiry date -->
        <div
          v-if="isExpirableCategory && !isNil(lastOpeningDate)"
          class="flex items-center mb-5"
        >
          <app-help-button class="mr-3">
            <p class="mb-2">
              {{ t('ttmt.form.hints.point_of_interest.expiry_date_details_1', { category: categoryLabel }) }}
            </p>
            <p class="mb-2">
              {{ t('ttmt.form.hints.point_of_interest.expiry_date_details_2', { date: formattedLastOpeningDate }) }}
            </p>
            <p class="mb-2">
              {{ t('ttmt.form.hints.point_of_interest.expiry_date_details_3') }}
            </p>
            <p>
              {{ t('ttmt.form.hints.point_of_interest.expiry_date_details_4') }}
            </p>
          </app-help-button>

          <p class="font-bold">
            {{ t('ttmt.form.hints.point_of_interest.expiry_date', { date: formattedLastOpeningDate }) }}
          </p>
        </div>

        <!-- Opening form -->
        <div>
          <form-label
            :label="t('ttmt.form.labels.point_of_interest.opening')"
            class="block mb-2"
          />
          <opening-form />
        </div>
      </app-card-item>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy, max, isNil } from 'lodash'
import { isNull } from 'lodash/lang'

import {
  fetchPointOfInterestCategories,
  fetchTags,
} from '@shared/http/api'
import useFormFields from '@shared/hooks/form/formFields'
import useLocale from '@shared/hooks/locale'
import { formatDateShort } from '@shared/helpers/datetime'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormTranslated from '@shared/components/form/FormTranslated.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import ImagesFormNested from '@extranet/components/resources/image/ImagesFormNested.vue'
import VideosFormNested from '@extranet/components/resources/point_of_interest/videos/VideosFormNested.vue'
import AudiosFormNested from '@extranet/components/resources/point_of_interest/audios/AudiosFormNested.vue'
import OpeningForm from '@extranet/components/resources/point_of_interest/opening_hours/OpeningForm.vue'
import LogoFormFields from '@extranet/components/resources/point_of_interest/logo/LogoFormFields.vue'
import AppHelpButton from '@shared/components/ui/AppHelpButton.vue'
import AddressFormFieldsTranslated from '@extranet/components/resources/point_of_interest/address/AddressFormFieldsTranslated.vue'
import 'country-flag-icons/3x2/flags.css'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const { t, locale } = useI18n()
const { form } = useFormFields(props)
const { localeOptionsByCountry, getAttributeTranslation } = useLocale()

// ---------- LOCALE ----------

const localeOptions = computed(() => (
  localeOptionsByCountry(form.value.values.address?.country)
))

// ---------- CATEGORIES ----------

// return categories options used in select control, based on categories resources
function formatCategoriesOptions(categories) {
  return categories.map((categoryResource) => ({
    label: getAttributeTranslation(categoryResource.attributes.label),
    value: categoryResource.id,
  }))
}

const initialCategoriesSearch = ref(true)
const categories = ref([])

function handleCategoriesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialCategoriesSearch.value) {
      initialCategoriesSearch.value = false

      if (props.resource?.relationships?.category) {
        // provide an option with the resource's relationship value
        options.push(formatCategoriesOptions([props.resource.relationships.category])[0])
      }
    }

    fetchPointOfInterestCategories({
      'filter[label]': searchKeywords,
    })
      .then((e) => {
        // Get the new options from the response
        options = options.concat(formatCategoriesOptions(e.data.data))

        // Avoid updating var after each select change
        if (categories.value.length <= 0) {
          categories.value = e.data.data
        }
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}

const selectedCategoryId = computed(() => (
  form.value.values.point_of_interest_category_id
))

const currentCategory = computed(() => (
  categories.value.find((c) => (
    c.id === selectedCategoryId.value
  ))
))

const isStarableCategory = computed(() => (
  currentCategory.value?.attributes?.starable
))

const isExpirableCategory = computed(() => (
  currentCategory.value?.attributes?.expirable
))

const categoryLabel = computed(() => (
  currentCategory.value?.attributes?.label?.[locale.value]
))

// ---------- TAGS ----------

const selectTags = ref()
const initialTagsSearch = ref(true)

// Return tags options used in select control,
// based on selected category
function formatTagsOptions(tags) {
  return tags.map((tagResource) => ({
    label: getAttributeTranslation(tagResource.attributes.label),
    value: tagResource.id,
  }))
}

function handleTagsSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []
    const params = {}

    if (initialTagsSearch.value) {
      initialTagsSearch.value = false

      if (props.resource?.relationships?.tags) {
        // Provide an option with the resource's relationship value
        options.push(formatTagsOptions(props.resource.relationships.tags))
      }
    }

    // Search keywords
    if (!isNull(searchKeywords)) {
      params.search = searchKeywords
    }

    // Filter by category
    if (!isNull(selectedCategoryId.value)) {
      params.categoryId = selectedCategoryId.value
    }

    fetchTags(params)
      .then((e) => {
        // Get the new options from the response
        options = options.concat(formatTagsOptions(e.data.data))
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}

function handleSelectTagsRefresh() {
  form.value.setFieldValue('tags_ids', null)
  selectTags.value?.$refs.formControlRef.$refs.multiselect.refreshOptions()
}

// ---------- IMPORT ----------

const isImport = computed(() => (
  props.resource?.attributes?.source === 'import'
    && props.resource?.attributes?.import_source === 'DataTourisme'
))

const importSynchronizing = computed(() => (
  isImport.value && form.value.values.import_synchronizing
))

// ---------- OPENING ----------

const openingHours = computed(() => (
  form.value.values.opening_hours ?? []
))

const lastOpeningDate = computed(() => (
  // Pick max date
  max(openingHours.value.map((specification) => (
    // Use "to" date if defined, or "from"
    specification.dates?.to ?? specification.dates?.from
  )))
))

const formattedLastOpeningDate = computed(() => (
  formatDateShort(lastOpeningDate.value)
))
</script>
