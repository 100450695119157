<template>
  <form
    id="point-of-interest-form"
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors
      class="mb-3"
    />

    <fieldset
      :disabled="submitting"
    >
      <!-- Sticky submit button when form is not in a modal -->
      <teleport-wrapper
        v-if="!displayFormModal"
        to="#header-scrolling-replacement"
      >
        <app-button
          form="point-of-interest-form"
          feature="save"
          :label="submitLabel"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
          class="float-right"
        />
      </teleport-wrapper>

      <!-- Sticky submit button when form is in a modal -->
      <div
        v-else
        class="sticky float-right top-0 px-8 py-4 z-10"
      >
        <app-button
          form="point-of-interest-form"
          feature="save"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
        />
      </div>

      <form-translatable-hint
        v-if="isStepFullForm"
        class="mb-4"
      />

      <app-alert
        v-if="isStepCheckDuplicate"
        design="info"
        :title="t('ttmt.points_of_interest.duplicates.check_explanation_title')"
        class="mb-4"
      >
        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation1') }}
        </p>

        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation2') }}
        </p>

        <p>
          {{ t('ttmt.points_of_interest.duplicates.check_explanation3') }}
        </p>
      </app-alert>

      <!-- Step 1 form fields -->
      <form-fields-duplicates
        v-if="isStepCheckDuplicate"
        :resource="resource"
        class="clear-both"
      />

      <!-- Step 2 form fields -->
      <form-fields-full
        v-if="isStepFullForm"
        :resource="resource"
        class="clear-both"
      />

      <!-- Duplicates modal -->
      <app-overlay
        :show="duplicatesModalDisplayed"
        @clicked="handleDuplicatesModalClose"
      >
        <app-modal
          ref="duplicatesModal"
          max-width-classes="max-w-full"
          text-align-classes="text-left"
          @closed="handleDuplicatesModalClose"
        >
          <div class="float-right flex items-center">
            <!-- Continue creation -->
            <app-button
              :label="t('ttmt.points_of_interest.duplicates.continue_creation')"
              emphasis="medium"
              class="mr-2"
              @click="continueCreation"
            />

            <!-- Go to selected POI's full page -->
            <app-button
              v-if="selectedDuplicateResource"
              :label="t('ttmt.points_of_interest.duplicates.view_poi')"
              @click="router.push({
                name: 'show-points-of-interest',
                params: { id: selectedDuplicateId }
              })"
            />
          </div>

          <div class="flex clear-both">
            <!-- Explanations about duplicates found -->
            <div class="min-w-1/4 w-1/4">
              <div class="text-left text-sm">
                <p class="mb-2">
                  {{ t('ttmt.points_of_interest.duplicates.found_explanation1') }}
                </p>

                <p>
                  {{ t('ttmt.points_of_interest.duplicates.found_explanation2') }}
                </p>
              </div>

              <!-- Duplicates list -->
              <app-card-item
                v-for="(duplicate, key) in duplicatesRelevantEnough"
                :key="key"
                class="mb-2 p-3 cursor-pointer"
                @click="selectDuplicate(duplicate.id)"
              >
                <point-of-interest-duplicate-card
                  :resource="duplicate"
                  :selected="duplicate.id === selectedDuplicateId"
                />
              </app-card-item>
            </div>

            <!-- Selected POI preview -->
            <div class="ml-8 flex-1">
              <point-of-interest-show
                v-if="selectedDuplicateResource"
                :resource="selectedDuplicateResource"
                :with-actions="false"
                :with-locale-selector="false"
                :with-employees="false"
                :with-reservations="false"
              />

              <!-- Placeholder / Hint -->
              <p
                v-else
                class="font-bold text-xl fixed left-1/2 top-1/2"
              >
                {{ t('ttmt.points_of_interest.duplicates.select_duplicate') }}
              </p>
            </div>
          </div>
        </app-modal>
      </app-overlay>

      <!-- Save -->
      <app-button
        feature="save"
        :label="submitLabel"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import usePoiCreateForm from '@extranet/hooks/poiCreateForm'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import FormTranslatableHint from '@extranet/components/form/FormTranslatableHint.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormFieldsFull from '@extranet/components/resources/point_of_interest/form/FormFieldsFull.vue'
import FormFieldsDuplicates from '@extranet/components/resources/point_of_interest/form/FormFieldsDuplicates.vue'
import PointOfInterestDuplicateCard from '@extranet/components/resources/point_of_interest/PointOfInterestDuplicateCard.vue'
import PointOfInterestShow from '@extranet/components/resources/point_of_interest/PointOfInterestShow.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not
  // generated by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const displayFormModal = computed(() => (
  store.state.modals.point_of_interest_form.display
))

// ---------- CREATE FORM ----------

const {
  selectDuplicate,
  duplicatesRelevantEnough,
  handleDuplicatesModalClose,
  continueCreation,
  duplicatesModalDisplayed,
  selectedDuplicateResource,
  selectedDuplicateId,
  invalid,
  isStepCheckDuplicate,
  isStepFullForm,
  submitLabel,
  onSubmit,
} = usePoiCreateForm(props, { emits })
</script>
