<template>
  <div class="flex items-center">
    <app-button
      v-if="resource.authorizations.view"
      feature="show"
      :title="t('ttmt.common.actions.show')"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleShow(resource.id)"
    />

    <app-button
      v-if="resource.authorizations.update"
      feature="edit"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleEdit(resource.id)"
    />

    <app-button
      v-if="resource.authorizations.confirm"
      feature="confirm"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleConfirm"
    />

    <app-button
      v-if="resource.authorizations.cancel"
      feature="cancel"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleCancel"
    />

    <app-button
      v-if="resource.authorizations.archive"
      feature="archive"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleArchive"
    />

    <app-button
      v-if="resource.authorizations.delete"
      feature="delete"
      emphasis="low"
      hide-text
      :hide-icon="false"
      class="mx-1"
      @click="handleDestroy"
    />
  </div>

  <!-- Delete modal -->
  <app-overlay
    :show="deleteConfirmModalDisplayed"
    @clicked="handleDestroyCancel"
  >
    <app-modal @closed="handleDestroyCancel">
      <app-confirm
        @cancelled="handleDestroyCancel"
        @confirmed="handleDestroyConfirm"
      >
        <p>
          {{ t('ttmt.reservations.destroy.confirm') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Confirm modal -->
  <app-overlay
    :show="confirmConfirmModalDisplayed"
    @clicked="handleConfirmCancel"
  >
    <app-modal @closed="handleConfirmCancel">
      <app-confirm
        @cancelled="handleConfirmCancel"
        @confirmed="handleConfirmConfirm"
      >
        <p>
          {{ t('ttmt.reservations.confirm.confirm') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Cancel modal -->
  <app-overlay
    :show="cancelConfirmModalDisplayed"
    @clicked="handleCancelCancel"
  >
    <app-modal @closed="handleCancelCancel">
      <app-confirm
        @cancelled="handleCancelCancel"
        @confirmed="handleCancelConfirm"
      >
        <p>
          {{ t('ttmt.reservations.cancel.confirm') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>

  <!-- Archive modal -->
  <app-overlay
    :show="archiveConfirmModalDisplayed"
    @clicked="handleArchiveCancel"
  >
    <app-modal @closed="handleArchiveCancel">
      <app-confirm
        @cancelled="handleArchiveCancel"
        @confirmed="handleArchiveConfirm"
      >
        <p>
          {{ t('ttmt.reservations.archive.confirm') }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Function called on confirm handling
  confirmCallback: {
    type: Function,
    default: () => ({}),
  },
  // Function called on cancel handling
  cancelCallback: {
    type: Function,
    default: () => ({}),
  },
  // Function called on archive handling
  archiveCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'destroyed',
  'confirmed',
  'canceled',
  'archived',
])

const router = useRouter()
const { t } = useI18n()

// ---------- SHOW ----------

function handleShow(id) {
  router.push({
    name: 'show-reservations',
    params: { id },
  })
}

// ---------- EDIT ----------

function handleEdit(id) {
  router.push({
    name: 'edit-reservations',
    params: { id },
  })
}

// ---------- DELETE ----------

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}

// ---------- CONFIRM ----------

const confirmConfirmModalDisplayed = ref(false)

function handleConfirm() {
  confirmConfirmModalDisplayed.value = true
}

function handleConfirmConfirm() {
  confirmConfirmModalDisplayed.value = false
  emits('confirmed')
  props.confirmCallback()
}

function handleConfirmCancel() {
  confirmConfirmModalDisplayed.value = false
}

// ---------- CANCEL ----------

const cancelConfirmModalDisplayed = ref(false)

function handleCancel() {
  cancelConfirmModalDisplayed.value = true
}

function handleCancelConfirm() {
  cancelConfirmModalDisplayed.value = false
  emits('canceled')
  props.cancelCallback()
}

function handleCancelCancel() {
  cancelConfirmModalDisplayed.value = false
}

// ---------- ARCHIVE ----------

const archiveConfirmModalDisplayed = ref(false)

function handleArchive() {
  archiveConfirmModalDisplayed.value = true
}

function handleArchiveConfirm() {
  archiveConfirmModalDisplayed.value = false
  emits('archived')
  props.archiveCallback()
}

function handleArchiveCancel() {
  archiveConfirmModalDisplayed.value = false
}
</script>
