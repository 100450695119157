<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <app-form-wizard
      :steps="steps"
    />
  </form>
</template>

<script setup>
import { shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

import useForm from '@shared/hooks/form/form'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import AppFormWizard from '@extranet/components/ui/form_wizard/AppFormWizard.vue'
import StepContext from '@extranet/components/features/claim/form/steps/StepContext.vue'
import StepDocuments from '@extranet/components/features/claim/form/steps/StepDocuments.vue'
import StepInfo from '@extranet/components/features/claim/form/steps/StepInfo.vue'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

// ---------- FORM ----------

const { handleSubmit } = useForm(props, { emits })

// ---------- FORM WIZARD ----------

const { t } = useI18n()

const steps = shallowRef([
  {
    step: 0,
    name: t('ttmt.claims.form_wizard.steps.context'),
    component: StepContext,
    props: {},
    emits: {},
    icon: {
      library: 'coolicon',
      name: 'settings',
      iconStyle: null,
    },
  },
  {
    step: 1,
    name: t('ttmt.claims.form_wizard.steps.documents'),
    component: StepDocuments,
    props: {},
    emits: {},
    icon: {
      library: 'coolicon',
      name: 'file_blank_outline',
      iconStyle: null,
    },
  },
  {
    step: 2,
    name: t('ttmt.claims.form_wizard.steps.info'),
    component: StepInfo,
    props: {},
    emits: {},
    icon: {
      library: 'coolicon',
      name: 'id_card',
      iconStyle: null,
    },
  },
])
</script>
