<template>
  <p class="font-bold text-xl mb-4">
    {{ t('ttmt.tutorials.extranet.dashboard.claim.title') }}
  </p>

  <p class="font-bold">
    {{ t('ttmt.tutorials.extranet.dashboard.claim.subtitle_1') }}
    {{ t('ttmt.tutorials.extranet.dashboard.claim.subtitle_2') }}
  </p>

  <ol class="mt-4 list-decimal list-inside">
    <li>
      {{ t('ttmt.tutorials.extranet.dashboard.claim.steps.filters') }}
    </li>

    <!-- eslint-disable vue/no-v-html -->
    <li v-html="t('ttmt.tutorials.extranet.dashboard.claim.steps.exist')" />

    <!-- eslint-disable vue/no-v-html -->
    <li v-html="t('ttmt.tutorials.extranet.dashboard.claim.steps.dont_exist')" />

    <li>
      {{ t('ttmt.tutorials.extranet.dashboard.claim.steps.claim_form') }}
    </li>

    <li>
      {{ t('ttmt.tutorials.extranet.dashboard.claim.steps.claim_validation') }}
    </li>
  </ol>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
