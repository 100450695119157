<template>
  <app-button
    v-if="resource.authorizations.claim"
    font-size="sm"
    :label="t('ttmt.common.actions.claim')"
    @click="router.push({
      name: 'claim-points-of-interest',
      params: { id: resource.id },
    })"
  />
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const router = useRouter()
</script>
