<template>
  <teleport-wrapper
    v-if="pointOfInterest && !store.state.spinner.active"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <point-of-interest-form
    v-if="pointOfInterest && !store.state.spinner.active"
    :resource="pointOfInterest"
    :additional-errors="pointOfInterestFormErrors"
    :submitting="pointOfInterestFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import {
  fetchPointOfInterest,
} from '@shared/http/api'
import breadcrumbPointOfInterest from '@extranet/breadcrumbs/pointOfInterest'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import PointOfInterestForm from '@extranet/components/resources/point_of_interest/PointOfInterestForm.vue'
import useLocale from '@shared/hooks/locale'
import usePointOfInterestForm from '@shared/hooks/resources/form/pointOfInterest'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- POINT OF INTEREST ----------

const pointOfInterest = ref({})

function fetchPoi() {
  store.commit('spinner/ENABLE')
  const { id } = route.params

  fetchPointOfInterest(id)
    .then((response) => {
      pointOfInterest.value = response.data.data
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

// ---------- FORM ----------

const {
  errors: pointOfInterestFormErrors,
  submitting: pointOfInterestFormSubmitting,
  handleSubmit,
} = usePointOfInterestForm({
  onSubmitSuccess,
  resource: pointOfInterest,
})

function onSubmitSuccess(response) {
  router.push({ name: 'show-points-of-interest', params: { id: response.data.data?.id } })
  store.commit('flashes/ADD_FLASH', { message: t('ttmt.points_of_interest.update.success'), type: 'success' })
}

// ---------- BREADCRUMBS ----------

const { edit } = breadcrumbPointOfInterest()

const breadcrumbs = computed(() => (
  edit({
    id: pointOfInterest.value?.id,
    label: getAttributeTranslation(pointOfInterest.value?.attributes?.title),
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPoi()
})
</script>
