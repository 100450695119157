<template>
  <app-alert
    design="danger"
    :title="t('ttmt.claims.notice.rejected.title')"
    class="mb-4"
  >
    <p>
      {{ t('ttmt.claims.notice.rejected.state') }}
    </p>

    <p>
      {{ resource?.attributes.denial_reason }}
    </p>
  </app-alert>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppAlert from '@shared/components/ui/AppAlert.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
</script>
