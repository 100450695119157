<template>
  <div>
    <template
      v-for="(specification, specificationIndex) in openingHours"
      :key="specificationIndex"
    >
      <form-group
        type="hidden"
        :name="`opening_hours.${specificationIndex}._destroy`"
        :initial-value="false"
      />

      <template
        v-if="!specification._destroy"
      >
        <div
          class="border-1 rounded p-3 relative my-3"
        >
          <!-- Remove button -->
          <app-button
            feature="close"
            :hide-icon="false"
            :hide-text="true"
            width="6"
            height="6"
            rounded="rounded-1/2"
            padding="p-0"
            :gradient="false"
            :icon-props="{ size: 'text-sm' }"
            class="absolute -top-2 -right-2 flex items-center justify-center"
            @click="handleRemove(specificationIndex)"
          />

          <!-- Specification form -->
          <opening-specification-form
            :name="`opening_hours.${specificationIndex}`"
          />
        </div>
      </template>
    </template>

    <app-button
      feature="add"
      :hide-icon="false"
      :label="capitalize(t('ttmt.form.labels.point_of_interest.opening_specification_add'))"
      :icon-props="{ size: 'text-sm' }"
      font-size="sm"
      @click="handleAdd"
    />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'
import { fromJS } from 'immutable'

import OpeningSpecificationForm from '@extranet/components/resources/point_of_interest/opening_hours/OpeningSpecificationForm.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const form = inject('form', ref({}))
const { t } = useI18n()

const openingHours = computed(() => (
  form.value.values.opening_hours ?? []
))

function getOpeningHoursCopy() {
  return fromJS(openingHours.value).toJS()
}

function handleAdd() {
  const openingHoursCopy = getOpeningHoursCopy()
  openingHoursCopy.push({})
  form.value.setFieldValue('opening_hours', openingHoursCopy)
}

function handleRemove(specificationIndex) {
  // Set "_destroy" form value to "true"
  form.value.setFieldValue(`opening_hours.${specificationIndex}`, { _destroy: true })
}
</script>
