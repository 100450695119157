<template>
  <form-group
    name="address.id"
    type="hidden"
  />

  <form-group
    :name="`address.autocomplete.${locale}`"
    :submittable="false"
    type="select"
    error-label-i18n-key="line1"
    rules="required"
    :label="t('validation.attributes.line1')"
    :initial-value="form?.values?.address?.line1?.[locale]"
    :hint="t('ttmt.form.hints.point_of_interest.line1')"
    :form-control-props="{
      filterable: false,
      options: handleAddressSearch,
      minChars: 3,
    }"
    @input="handleAutocompleteInput"
    @changed="handleAddressChange"
  >
    <template #control-vue-multiselect-option="option">
      <div>
        <template v-if="option.icon">
          <!-- set a key to force icon to re-render when options change -->
          <font-awesome-icon
            :key="option.icon"
            :icon="option.icon"
          />
          &nbsp;
        </template>
        <strong>
          {{ option.label }}
        </strong>

        <template v-if="option.subLabel">
          <br>
          <em class="text-sm">
            {{ option.subLabel }}
          </em>
        </template>
      </div>
    </template>
  </form-group>

  <form-group
    :name="`address.line1.${locale}`"
    type="hidden"
  />

  <form-group
    :name="`address.line2.${locale}`"
    type="text"
    @changed="resetCoords"
  />

  <div :class="fieldsStyle">
    <form-group
      name="address.zip"
      type="text"
      @changed="resetCoords"
    />

    <form-group
      rules="required"
      :name="`address.city.${locale}`"
      type="text"
      @changed="resetCoords"
    />
  </div>

  <div :class="fieldsStyle">
    <form-group
      :name="`address.dependent_locality.${locale}`"
      type="text"
      @changed="resetCoords"
    />

    <form-group
      :name="`address.administrative_area.${locale}`"
      type="text"
      @changed="resetCoords"
    />
  </div>

  <form-group
    rules="required"
    name="address.country"
    type="select"
    :form-control-props="{
      options: countries,
    }"
    @changed="handleCountryChange"
  >
    <template #control-vue-multiselect-option="option">
      <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
    </template>
    <template #control-vue-multiselect-singlelabel="option">
      <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
    </template>
  </form-group>

  <form-group
    name="address.latitude"
    type="hidden"
  />

  <form-group
    name="address.longitude"
    type="hidden"
  />
</template>

<script setup>
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy, uniq } from 'lodash'
import { countries as countriesList } from 'countries-list'

import * as mapbox from '@shared/http/mapbox'
import useGeoloc from '@shared/hooks/geoloc'
import useFormFields from '@shared/hooks/form/formFields'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Display some fields on two columns
  twoCols: {
    type: Boolean,
    default: false,
  },
})

const { t, locale } = useI18n()
const { form } = useFormFields(props)
const { countries } = useGeoloc()

// ---------- ADDRESS OPTIONS ----------

const addressOptionsFromApi = ref([])
const initialAddressSearch = ref(true)

function handleAddressSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialAddressSearch.value) {
      initialAddressSearch.value = false

      if (form.value?.values?.address?.line1?.[locale.value]) {
        // Provide an option with the resource's attribute value
        options.push({
          label: form.value.values.address.line1[locale.value],
          value: form.value.values.address.line1[locale.value],
        })
      }
    }

    // add search keyword to available options
    if (searchKeywords?.length > 0) {
      options.push({
        value: searchKeywords,
        label: searchKeywords,
        icon: 'pen',
      })

      mapbox.geocoding(searchKeywords, {
        types: [
          'address',
          'poi',
        ].join(','),
        language: locale.value,
      })
        .then((r) => {
          addressOptionsFromApi.value = r.data.features
            ?.map((place) => {
              // Build option's label
              let label = ''
              // Specify address in the label when available
              if (place.place_type.includes('address') && place.address) {
                label = `${place.address} ${place.text}`
              } else if (place.place_type.includes('poi') && place.properties?.address) {
                label = `${place.text}, ${place.properties.address}`
              } else {
                label = place.text
              }

              // Adapt icon to place's type
              let icon
              if (place.place_type.includes('poi')) {
                icon = 'map-marker-alt'
              } else if (place.place_type.includes('address')) {
                icon = 'map-signs'
              }

              // Build sublabel from place's context
              const subLabel = uniq(place.context?.map((c) => c.text))
                .join(', ')

              return {
                value: place.id,
                label,
                subLabel,
                icon,
                context: place.context,
                coords: {
                  latitude: place.center[1],
                  longitude: place.center[0],
                },
              }
            })
        })
        .finally(() => {
          options = options.concat(addressOptionsFromApi.value)
          resolve(uniqBy(options, 'value'))
        })
    } else {
      resolve(options)
    }
  })
}

// Sync line 1 hidden field's value with autocomplete input
function handleAutocompleteInput(event) {
  form.value.setFieldValue(
    `address.line1.${locale.value}`,
    event.target.value,
  )
}

async function handleAddressChange(value) {
  // Retrieve selected option in api options list
  const option = addressOptionsFromApi.value.find((filter) => (
    filter.value === value
  ))

  // If option retrieved
  // Use option's label as line 1 hidden field's value
  // Else, keep line 1 value
  if (option) {
    form.value.setFieldValue(
      `address.line1.${locale.value}`,
      option.label ?? null, // Force to null to avoid undefined value
    )
  }

  // fill other form fields from mapbox context data
  // if context data is undefined, force field to null
  if (option?.context) {
    let context_dependent_locality = option.context.find(
      (context) => context.id.startsWith('locality'),
    )

    if (!context_dependent_locality) {
      context_dependent_locality = option.context.find(
        (context) => context.id.startsWith('neighborhood'),
      )
    }

    form.value.setFieldValue(
      `address.dependent_locality.${locale.value}`,
      context_dependent_locality?.text || null,
    )

    const context_city = option.context.find(
      (context) => context.id.startsWith('place'),
    )

    form.value.setFieldValue(
      `address.city.${locale.value}`,
      context_city?.text || null,
    )

    const context_zip = option.context.find(
      (context) => context.id.startsWith('postcode'),
    )

    form.value.setFieldValue(
      'address.zip',
      context_zip?.text || null,
    )

    let context_administrative_area = option.context.find(
      (context) => context.id.startsWith('region'),
    )

    if (!context_administrative_area) {
      context_administrative_area = option.context.find(
        (context) => context.id.startsWith('district'),
      )
    }

    form.value.setFieldValue(
      `address.administrative_area.${locale.value}`,
      context_administrative_area?.text || null,
    )

    const context_country = option.context.find(
      (context) => context.id.startsWith('country'),
    )

    form.value.setFieldValue(
      'address.country',
      context_country?.short_code?.toUpperCase() || null,
    )

    form.value.setFieldValue(
      `address.line2.${locale.value}`,
      null,
    )
  }

  // Reset latitude and longitude anyway
  resetCoords()

  // Wait for each address field to change, which reset coords, and at last set the new coords
  await nextTick()
  if (option?.coords) {
    form.value.setFieldValue(
      'address.latitude',
      option.coords.latitude,
    )

    form.value.setFieldValue(
      'address.longitude',
      option.coords.longitude,
    )
  }
}

function handleCountryChange(country) {
  resetCoords()

  // adapt phone country code if field is empty
  if (!form.value.values.contact.phone) {
    form.value.setFieldValue(
      'contact.phone_country_code',
      country,
    )
  }

  // adapt language field
  form.value.setFieldValue(
    'locale',
    countriesList[country]?.languages?.[0],
  )
}

function resetCoords() {
  form.value.setFieldValue(
    'address.latitude',
    null,
  )

  form.value.setFieldValue(
    'address.longitude',
    null,
  )
}

// ---------- STYLE ----------

const fieldsStyle = computed(() => (
  props.twoCols ? 'grid grid-cols-1 lg:grid-cols-2 gap-4' : ''
))
</script>
