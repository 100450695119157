<template>
  <div
    class="bg-white rounded-2lg max-w-full mx-auto fixed left-1/2 -translate-x-1/2 z-30 w-96 h-12"
  >
    <span class="float-right pt-1 pr-2">
      <app-button
        feature="close"
        emphasis="low"
        padding="py-1"
        :hide-text="true"
        :hide-icon="false"
        @click="handleCloseClick"
      />
    </span>

    <div class="h-full flex items-center py-1 px-4 font-semibold">
      <div
        v-if="!recording"
        class="cursor-pointer"
        @click="startRecording"
      >
        <font-awesome-icon
          icon="circle"
          class="text-red-500 "
        />
        {{ t('ttmt.common.actions.start') }}
      </div>

      <div
        v-else
      >
        <span
          class="inline-block mr-4"
        >
          <span class="inline-block w-5">
            {{ secondsRecordedDisplayed }}
          </span>

          <span class="text-gray-400 text-xs">
            / {{ maxDurationDisplayed }}
          </span>
        </span>

        <span
          class="mr-5 text-xs cursor-pointer"
          @click="saveRecording"
        >
          <font-awesome-icon
            icon="square"
            class="text-red-500"
          />
          {{ t('ttmt.common.actions.save_alt') }}
        </span>

        <span
          v-if="paused"
          class="text-xs"
          @click="resumeRecording"
        >
          {{ t('ttmt.common.actions.resume') }}
        </span>

        <span
          v-else
          class="text-xs cursor-pointer"
          @click="pauseRecording"
        >
          <font-awesome-icon
            icon="pause"
          />
          {{ t('ttmt.common.actions.pause') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import AppButton from '@shared/components/ui/AppButton.vue'

const emits = defineEmits([
  'closed',
  'saved',
])
const store = useStore()
const { locale, t } = useI18n()

const recording = ref(false)
const paused = ref(false)
const maxDuration = 30 // max duration of a recorded audio
const secondsRecorded = ref(0) // seconds recorded number
const secondsRecordedInterval = ref(null) // Interval object to increase seconds recorded
const mediaRecorder = ref(null) // browser media recorder
const audioChunks = ref([]) // audio chunks recorded
const stream = ref(null) // browser media stream

const maxDurationDisplayed = computed(() => (
  Math.round(maxDuration).toLocaleString(locale, { minimumIntegerDigits: 2 })
))

const secondsRecordedDisplayed = computed(() => (
  Math.round(secondsRecorded.value).toLocaleString(locale, { minimumIntegerDigits: 2 })
))

function startRecording() {
  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((newStream) => {
      stream.value = newStream
      recording.value = true
      increaseSecondsRecorded()

      mediaRecorder.value = new MediaRecorder(stream.value)
      mediaRecorder.value.start()

      mediaRecorder.value.ondataavailable = ((event) => {
        audioChunks.value.push(event.data)
      })

      mediaRecorder.value.onstop = (() => {
        stopRecording()
      })
    })
    .catch((e) => {
      if (e.name === 'NotAllowedError') {
        store.commit('flashes/ADD_FLASH', { message: t('ttmt.media.audio.recording.errors.not_allowed'), type: 'danger' })
      } else {
        store.commit('flashes/ADD_FLASH', { message: t('ttmt.media.audio.recording.errors.default'), type: 'danger' })
      }
      emits('closed')
    })
}

const fileToSave = ref(false) // file needed to be saved when recorder stop

function stopRecording() {
  const audioBlob = new Blob(audioChunks.value)
  const audioFile = new File([audioBlob], 'record.mp3', { type: 'audio/mp3' })

  if (fileToSave.value) {
    emits('saved', audioFile)
  }

  resetSecondsRecorded()
  recording.value = false
  paused.value = false
  audioChunks.value = []

  closeStream()
}

function pauseRecording() {
  mediaRecorder.value.pause()
  paused.value = true
}

function resumeRecording() {
  mediaRecorder.value.resume()
  paused.value = false
}

function saveRecording() {
  fileToSave.value = true
  mediaRecorder.value.stop()
}

function increaseSecondsRecorded() {
  secondsRecordedInterval.value = setInterval(() => {
    if (recording.value && !paused.value) {
      secondsRecorded.value += 1
    }

    // Force the recording to stop if audio is too long
    if (secondsRecorded.value >= maxDuration) {
      fileToSave.value = true
      mediaRecorder.value.stop()
    }
  }, 1000)
}

function resetSecondsRecorded() {
  clearInterval(secondsRecordedInterval.value)
  secondsRecorded.value = 0
}

function handleCloseClick() {
  closeStream()
  emits('closed')
}

// Stop the browser media stream
function closeStream() {
  stream.value?.getTracks()?.forEach((track) => {
    track.stop()
  })
  stream.value = null
}

onUnmounted(() => {
  closeStream()
})
</script>
