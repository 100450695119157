<template>
  <div ref="appTableContainerRef">
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="loading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchReservations as fetchApiReservations,
  destroyReservation,
  confirmReservation,
  cancelReservation,
  archiveReservation,
} from '@shared/http/api'
import useDatetime from '@shared/hooks/datetime'
import useReservation from '@extranet/hooks/reservation'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'
import AppUserWithAvatar from '@extranet/components/ui/user/AppUserWithAvatar.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import ReservationTableActions from '@extranet/components/resources/reservation/ReservationTableActions.vue'

const props = defineProps({
  // Mode used to fetch data
  mode: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these strings
      return [
        'butler',
        'traveller',
        'poi',
      ].includes(value)
    },
  },
  // Parameter used to filter in
  // some modes
  filterId: {
    type: [String, Number],
    default: '',
  },
})

const store = useStore()
const { t } = useI18n()
const {
  formatDateShort,
  formatTimeSimple,
} = useDatetime()

// ---------- RESERVATIONS ----------

const reservations = ref([])
const loading = ref(true)

function fetchReservations(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'mode': props.mode,
    'filterId': props.filterId,
  }

  fetchApiReservations(params)
    .then((response) => {
      const oldReservations = reservations.value
      const newReservations = response.data.data?.map((reservation) => ({
        resource: reservation,
        destroyCallback: () => handleDestroy(reservation.id),
        confirmCallback: () => handleConfirm(reservation.id),
        cancelCallback: () => handleCancel(reservation.id),
        archiveCallback: () => handleArchive(reservation.id),
      })) ?? []

      if (nextPage) {
        // add new results after the old ones
        reservations.value = [...oldReservations, ...newReservations]
      } else {
        // replace the old results with the new ones
        reservations.value = newReservations
      }

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function handleDestroy(id) {
  destroyReservation(id)
    .then(() => {
      resetPage()
      fetchReservations()
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('ttmt.reservations.destroy.success'), type: 'success' },
      )
    })
}

function handleConfirm(id) {
  confirmReservation(id)
    .then(() => {
      resetPage()
      fetchReservations()
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('ttmt.reservations.confirm.success'), type: 'success' },
      )
    })
}

function handleCancel(id) {
  cancelReservation(id)
    .then(() => {
      resetPage()
      fetchReservations()
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('ttmt.reservations.cancel.success'), type: 'success' },
      )
    })
}

function handleArchive(id) {
  archiveReservation(id)
    .then(() => {
      resetPage()
      fetchReservations()
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('ttmt.reservations.archive.success'), type: 'success' },
      )
    })
}

// ---------- DATA TABLE ----------

const { statusBadgeDesign } = useReservation()

const headers = [
  { label: t(`ttmt.reservations.pages.index.mode.first_col_label.${props.mode}`) },
  { label: t('validation.attributes.reservation_date') },
  { label: t('validation.attributes.reservation_time') },
  { label: t('validation.attributes.state') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  reservations?.value?.map((reservation) => ({
    institution: firstColumnContent(reservation),
    reservation_date: formatDateShort(reservation.resource.attributes.reservation_date),
    reservation_time: formatTimeSimple(reservation.resource.attributes.reservation_time),
    state: {
      label: t('validation.attributes.state'),
      component: AppStatusBadge,
      props: {
        design: statusBadgeDesign(reservation.resource.attributes.state),
        text: t(`ttmt.reservations.states.${reservation.resource.attributes.state}`),
      },
    },
    actions: {
      component: ReservationTableActions,
      props: {
        resource: reservation.resource,
        destroyCallback: () => handleDestroy(reservation.resource.id),
        confirmCallback: () => handleConfirm(reservation.resource.id),
        cancelCallback: () => handleCancel(reservation.resource.id),
        archiveCallback: () => handleArchive(reservation.resource.id),
      },
    },
  }))
))

function firstColumnContent(reservation) {
  switch (props.mode) {
    case 'poi':
      return {
        component: AppUserWithAvatar,
        props: {
          resource: reservation.resource?.relationships?.traveller,
        },
      }
    case 'traveller':
    case 'butler':
    default:
      return {
        component: PointOfInterestWithMainPicture,
        props: {
          resource: reservation.resource?.relationships?.point_of_interest,
        },
      }
  }
}

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const nextPageFetching = ref(false)
const links = ref([])
const page = ref(1)

function resetPage() {
  page.value = 1
}

function handleChangePage(value) {
  page.value = value
  fetchReservations()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchReservations()
})
</script>
