<template>
  <div>
    <!-- Dates -->
    <opening-dates-form
      :name="`${name}.dates`"
      class="mb-6"
    />

    <!-- Schedules -->
    <template
      v-for="(schedule, scheduleIndex) in schedules"
      :key="scheduleIndex"
    >
      <form-group
        type="hidden"
        :name="`${name}.schedules.${scheduleIndex}._destroy`"
        :initial-value="false"
      />

      <template
        v-if="!schedule._destroy"
      >
        <div
          class="border-1 rounded p-3 relative my-2"
        >
          <!-- Remove button -->
          <app-button
            feature="close"
            :hide-icon="false"
            :hide-text="true"
            width="5"
            height="5"
            rounded="rounded-1/2"
            padding="p-0"
            :gradient="false"
            :icon-props="{ size: 'text-xs' }"
            class="absolute -top-2 -right-2 flex items-center justify-center"
            @click="handleRemove(scheduleIndex)"
          />

          <!-- Schedule form -->
          <opening-schedule-form
            :name="`${name}.schedules.${scheduleIndex}`"
            :with-days="!singleDate"
          />
        </div>
      </template>
    </template>

    <!-- Add schedules -->
    <app-button
      feature="add"
      :hide-icon="false"
      :label="capitalize(scheduleAddLabel)"
      :icon-props="{ size: 'text-xs' }"
      font-size="xs"
      class="mb-5"
      @click="handleAdd"
    />

    <!-- Additional information -->
    <form-translated
      :form-group-props="{
        name: `${name}.additional_information`,
        type: 'textarea',
        formControlProps: {
          rows: 4,
        },
      }"
    />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { get, capitalize } from 'lodash'
import { fromJS } from 'immutable'

import OpeningDatesForm from '@extranet/components/resources/point_of_interest/opening_hours/OpeningDatesForm.vue'
import OpeningScheduleForm from '@extranet/components/resources/point_of_interest/opening_hours/OpeningScheduleForm.vue'
import FormTranslated from '@shared/components/form/FormTranslated.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Prefix used for form group's names
  name: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

const form = inject('form', ref({}))

const schedules = computed(() => (
  get(form.value.values, `${props.name}.schedules`) ?? []
))

function getSchedulesCopy() {
  return fromJS(schedules.value).toJS()
}

function handleAdd() {
  const schedulesCopy = getSchedulesCopy()
  schedulesCopy.push({})
  form.value.setFieldValue(`${props.name}.schedules`, schedulesCopy)
}

function handleRemove(scheduleIndex) {
  // Set "_destroy" form value to "true"
  form.value.setFieldValue(`${props.name}.schedules.${scheduleIndex}`, { _destroy: true })
}

const singleDate = computed(() => (
  !get(form.value.values, `${props.name}.dates.is_range`)
))

// Schedule add label depends on whether date is single or a range
const scheduleAddLabel = computed(() => (
  singleDate.value
    ? t('ttmt.form.labels.point_of_interest.opening_schedule_add_alt')
    : t('ttmt.form.labels.point_of_interest.opening_schedule_add')
))
</script>
