<template>
  <div class="relative w-36">
    <!-- Image preview -->
    <img
      v-if="imageUrl"
      :src="imageUrl"
      class="w-36"
    >

    <!-- Add button -->
    <app-button
      v-else
      feature="add"
      :hide-icon="false"
      :hide-text="true"
      rounded="rounded-md"
      :icon-props="{ size: 'text-5xl' }"
      width="36"
      height="18"
      padding="p-0"
      font-color-classes="text-theme-500 hover:text-theme-600"
      background-classes="bg-white"
      @click="handlePlusClick"
    />

    <!-- Remove button -->
    <app-button
      v-if="imageUrl"
      feature="close"
      :hide-icon="false"
      :hide-text="true"
      width="6"
      height="6"
      rounded="rounded-1/2"
      padding="p-0"
      :gradient="false"
      :icon-props="{ size: 'text-sm' }"
      class="absolute -top-2 -right-2 flex items-center justify-center"
      @click="handleDeleteLogo"
    />

    <form-error-messages
      :name="nameToUse('file')"
      :data-form-errors="true"
      :error-messages="fileErrors.flat()"
    />

    <!-- Hidden fields -->
    <form-group
      :name="nameToUse('id')"
      type="hidden"
    />

    <form-group
      :name="nameToUse('_destroy')"
      type="hidden"
    />

    <form-group
      :name="nameToUse('file')"
      :rules="fileRules"
      type="hidden"
    />

    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :accept="imageMimeTypes"
      @change="handleFileUpload"
    >

    <form-group
      :name="nameToUse('collection_name')"
      initial-value="logo"
      type="hidden"
    />

    <form-group
      :name="nameToUse('name')"
      :initial-value="t('validation.attributes.logo')"
      type="hidden"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { get } from 'lodash'
import { useField } from 'vee-validate'

import FormGroup from '@shared/components/form/FormGroup.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import useFormFields from '@shared/hooks/form/formFields'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const {
  markedForDestruction,
  nameToUse,
  form,
} = useFormFields(props)

const fileInput = ref() // file input template ref

const {
  errors: fileErrors,
} = useField(nameToUse('file'))

const imageMimeTypes = import.meta.env.VITE_VALID_IMAGE_MIMETYPES
const imageMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB
const imageMaxWidth = import.meta.env.VITE_VALID_MAX_IMAGE_WIDTH
const imageMaxHeight = import.meta.env.VITE_VALID_MAX_IMAGE_HEIGHT

const fileRules = [
  `mimes:${imageMimeTypes}`,
  `size.file:${imageMaxSize}`,
  `dimensions:max_width=${imageMaxWidth},max_height=${imageMaxHeight}`,
].join('|')

const currentFile = computed(() => (
  get(form.value?.values, nameToUse('file'))
))

const imageUrl = computed(() => {
  // No picture url if it is marked for destruction
  if (!markedForDestruction.value) {
    // Uploaded picture
    if (currentFile.value) {
      return URL.createObjectURL(currentFile.value)
    }

    // resource current picture
    return props.resource?.attributes?.urls?.xs
  }

  return null
})

function handlePlusClick() {
  fileInput.value.click()
}

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]
  if (targetFile) {
    // assign new picture value
    form.value?.setFieldValue(nameToUse('file'), targetFile)
    form.value?.setFieldValue(nameToUse('_destroy'), false)
  }
}

function handleDeleteLogo() {
  // Un-assign picture value
  form.value?.setFieldValue('logo.file', null)
  // Mark picture for destruction
  form.value?.setFieldValue('logo._destroy', true)
}
</script>
