<template>
  <div :class="containerStyle">
    <!-- Details -->
    <app-card-item
      class="p-4"
    >
      <form-group
        :name="`title.${locale}`"
        rules="required"
      />

      <form-group
        name="point_of_interest_category_id"
        rules="required"
        type="select"
        :form-control-props="{
          options: handleCategoriesSearch,
          filterable: false,
        }"
      />

      <address-form-fields
        :resource="resource.relationships?.address"
        :two-cols="fullWidth"
      />

      <form-group
        name="locale"
        rules="required"
        type="select"
        :form-control-props="{
          options: localeOptions,
        }"
        :hint="t('ttmt.form.hints.point_of_interest.locale')"
      />

      <div :class="fieldsStyle">
        <form-group
          rules="phone"
          name="contact.phone"
          type="tel"
        />

        <form-group
          rules="email"
          name="contact.email"
          type="email"
        />
      </div>

      <form-group
        name="contact.website"
        type="url"
      />
    </app-card-item>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { uniqBy } from 'lodash'

import {
  fetchPointOfInterestCategories,
} from '@shared/http/api'
import useFormFields from '@shared/hooks/form/formFields'
import useLocale from '@shared/hooks/locale'
import FormGroup from '@shared/components/form/FormGroup.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AddressFormFields from '@extranet/components/resources/point_of_interest/address/AddressFormFields.vue'
import 'country-flag-icons/3x2/flags.css'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
  // Display form in full width
  fullWidth: {
    type: Boolean,
    default: false,
  },
})

const { t, locale } = useI18n()
const { form } = useFormFields(props)
const { localeOptionsByCountry, getAttributeTranslation } = useLocale()

// ---------- LOCALE ----------

const localeOptions = computed(() => (
  localeOptionsByCountry(form.value.values.address?.country)
))

// ---------- CATEGORIES ----------

// return categories options used in select control, based on categories resources
function formatCategoriesOptions(categories) {
  return categories.map((categoryResource) => ({
    label: getAttributeTranslation(categoryResource.attributes.label),
    value: categoryResource.id,
  }))
}

const initialCategoriesSearch = ref(true)
const categories = ref([])

function handleCategoriesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialCategoriesSearch.value) {
      initialCategoriesSearch.value = false

      if (props.resource?.relationships?.category) {
        // provide an option with the resource's relationship value
        options.push(formatCategoriesOptions([props.resource.relationships.category])[0])
      }
    }

    fetchPointOfInterestCategories({
      'filter[label]': searchKeywords,
    })
      .then((e) => {
        // Get the new options from the response
        options = options.concat(formatCategoriesOptions(e.data.data))

        // Avoid updating var after each select change
        if (categories.value.length <= 0) {
          categories.value = e.data.data
        }
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}

// ---------- STYLE ----------

const gridStyle = 'grid grid-cols-1 lg:grid-cols-2 gap-4'

const containerStyle = computed(() => (
  props.fullWidth ? '' : gridStyle
))

const fieldsStyle = computed(() => (
  props.fullWidth ? gridStyle : ''
))
</script>
