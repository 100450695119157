<template>
  <carousel
    :items-to-show="2"
    :wrap-around="previewImages.length > 2"
  >
    <slide
      v-for="(image, index) in previewImages"
      :key="index"
    >
      <img
        :src="image.attributes.urls.sm"
        class="w-64 h-40 object-cover rounded-md cursor-pointer duration-300 hover:scale-105"
        @click="handleGalleryOpen(index)"
      >
    </slide>

    <template #addons>
      <navigation
        v-if="previewImages.length > 1"
      />
    </template>
  </carousel>

  <!-- Gallery modal -->
  <app-overlay
    :show="galleryModalDisplayed"
    @clicked="handleGalleryClose"
  >
    <app-modal @closed="handleGalleryClose">
      <!-- Slides -->
      <carousel
        v-if="images.length > 0"
        ref="galleryCarousel"
        class="mx-16"
        :items-to-show="1"
        wrap-around
        :autoplay="autoplayTime"
        :mouse-drag="images.length > 1"
        :touch-drag="images.length > 1"
      >
        <slide
          v-for="(image, i) in images"
          :key="i"
        >
          <a
            :href="image.attributes.urls.original"
            target="_blank"
            class="cursor-pointer"
          >
            <img
              :src="image.attributes.urls.md"
              class="rounded-md max-h-[500px] px-16"
            >

            <p v-if="getImageCredits(image)">
              {{ t('ttmt.media.image.credits', { credits: getImageCredits(image) }) }}
            </p>
          </a>
        </slide>

        <template #addons>
          <navigation
            v-if="displayNavigation"
            class=""
          />
        </template>
      </carousel>

      <!-- Navigation -->
      <div
        v-if="displayNavigation"
        class="overflow-x-auto mt-2 flex items-center justify-center"
      >
        <img
          v-for="(image, i) in images"
          :key="i"
          :src="image.attributes.urls.xs"
          class="mx-1 w-32 h-16 object-cover cursor-pointer rounded-md"
          @click="selectImage(i)"
        >
      </div>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import {
  Carousel,
  Slide,
  Navigation,
} from 'vue3-carousel'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import useImagesGallery from '@shared/hooks/imagesGallery'

const { t } = useI18n()

const props = defineProps({
  // Images to display
  images: {
    type: Array,
    default: () => ([]),
  },
})

function getImageCredits(image) {
  return image.attributes.credits
}

const {
  handleGalleryOpen,
  handleGalleryClose,
  autoplayTime,
  displayNavigation,
  previewImages,
  galleryCarousel,
  galleryModalDisplayed,
  selectImage,
} = useImagesGallery(props)
</script>
