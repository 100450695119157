<template>
  <div class="flex items-center mb-3">
    <app-audio-player
      v-if="audioUrl"
      :src="audioUrl"
      class="mr-2"
    />

    <app-button
      feature="close"
      :hide-icon="false"
      hide-text
      :gradient="false"
      padding="p-0"
      rounded="rounded-1/2"
      width="10"
      height="10"
      class="leading-none"
      :icon-props="{ size: 'text-xl' }"
      @click="handleDelete"
    />
  </div>

  <form-error-messages
    :name="nameToUse('file')"
    :data-form-errors="true"
    :error-messages="fileErrors.flat()"
  />

  <form-group
    :name="nameToUse('name')"
    :label="false"
    :placeholder="t('ttmt.form.placeholders.audio_file_name')"
    rules="required"
    type="text"
  />

  <form-group
    :name="nameToUse('id')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('_destroy')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('file')"
    :rules="fileRules"
    type="hidden"
  />

  <input
    ref="fileInput"
    type="file"
    class="hidden"
    :accept="audioMimeTypes"
    @change="handleFileUpload"
  >

  <form-group
    :name="nameToUse('position')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('source')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('collection_name')"
    initial-value="audios"
    type="hidden"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { get } from 'lodash'
import { useField } from 'vee-validate'

import AppAudioPlayer from '@shared/components/ui/AppAudioPlayer.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import useFormFields from '@shared/hooks/form/formFields'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'deleted',
  'file-uploaded',
])

const { t } = useI18n()

const {
  markedForDestruction,
  nameToUse,
  form,
} = useFormFields(props)

const fileInput = ref() // file input template ref

const {
  errors: fileErrors,
} = useField(nameToUse('file'))

const audioMimeTypes = import.meta.env.VITE_VALID_AUDIO_MIMETYPES
const audioMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB

const fileRules = [
  `mimes:${audioMimeTypes}`,
  `size.file:${audioMaxSize}`,
].join('|')

const currentFile = computed(() => (
  get(form.value?.values, nameToUse('file'))
))

const audioUrl = computed(() => {
  // no picture url if it is marked for destruction
  if (!markedForDestruction.value) {
    // uploaded picture
    if (currentFile.value) {
      return URL.createObjectURL(currentFile.value)
    }

    // resource current picture
    return props.resource?.attributes?.url
  }

  return null
})

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]
  if (targetFile) {
    // assign new picture value
    form.value?.setFieldValue(nameToUse('file'), targetFile)
    form.value?.setFieldValue(nameToUse('_destroy'), false)

    emits('file-uploaded')
  }
}

function handleDelete() {
  emits('deleted')
}
</script>
